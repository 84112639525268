/**
 * BEGIN: COLUMN PRESETS
 * ---------------------------------------------------------------------------------------
 */
.row_preset {
    padding:3px;
    background-color:#FFF;
    float:left;
    margin-right:25px;
}
.row_item {
    float:left;
}

.col_item {
    height:1px;
    background-color:#9D9D9D;
    margin:0px 1px 1px 0px;
    display:block;
}
.col_item.col_1 {
    width:2px;
}
.col_item.col_2 {
    width:4px;
}
.col_item.col_3 {
    width:6px;
}
.col_item.col_4 {
    width:8px;
}
.col_item.col_5 {
    width:10px;
}
.col_item.col_6 {
    width:12px;
}
.col_item.col_7 {
    width:14px;
}
.col_item.col_8 {
    width:16px;
}
.col_item.col_9 {
    width:18px;
}
.col_item.col_10 {
    width:20px;
}
.col_item.col_11 {
    width:22px;
}
.col_item.col_12 {
    width:24px;
}

.portlet-sortable-empty,
.portlet-sortable-empty2 {
    margin:0;
    height:15px;
}
/**
 * ---------------------------------------------------------------------------------------
 * END: COLUMN PRESETS
 */


/**
 * BEGIN: COLUMN SETTINGS
 * ---------------------------------------------------------------------------------------
 */
.column_settings .form-group {
    width:100%;
}
.column_settings .form-group:first-child {
    margin-top:15px;
}
.column_settings .btn {
    margin-button:15px;
}
.column_settings .form-group .input-group-addon {
    width:90px;
}
/**
 * ---------------------------------------------------------------------------------------
 * END: COLUMN SETTINGS
 */


.module_for_duplicate {
    border:1px solid #45B6AF;
}
.module_for_duplicate:hover {
    border:1px solid #428BCA;
}

.btn-cms {
    height:32px;
}