/* MENU SIDEBAR */
.menu-portlet-pic img {
    float: none;
    margin: 0 auto;
    width: 50%;
    height: 50%;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important; }

.menu-sidebar-portlet {
  padding: 15px 0 0 0 !important; }

.menu-portlet-title {
    text-align: center;
    margin-top: 20px; }

.menu-portlet-title-main {
    color: #5a7391;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 7px; }

.menu-portlet-title-sub {
    text-transform: uppercase;
    color: #5b9bd1;
    font-size: 13px;
    font-weight: 800;
    margin-bottom: 7px; }

.menu-portlet {
   margin-top: 0px;
   padding-bottom: 20px; }

.menu-portlet ul li {
   border-bottom: 1px solid #f0f4f7; }

.menu-portlet ul li:last-child {
   border-bottom: none; }

.menu-portlet ul li a {
   color: #93a3b5;
   font-size: 16px;
   font-weight: 400; }

.menu-portlet ul li a i {
   margin-right: 8px;
   font-size: 16px; }

.menu-portlet ul li a:hover {
   background-color: #fafcfd;
   color: #5b9bd1; }

.menu-portlet ul li.active a {
   color: #5b9bd1;
   background-color: #f6f9fb;
   border-left: 2px solid #5b9bd1;
}

/* END MENU SIDEBAR */
/* RESPONSIVE MODE */
@media (max-width: 991px) {
  /* 991px */
  /* 991px */
  .profile-sidebar {
        float: none;
        width: 100% !important;
        margin: 0; }
  .profile-sidebar > .portlet {
       margin-bottom: 20px; }
  .profile-content {
       overflow: visible; } }
